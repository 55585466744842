// Buttons
@mixin base-button-styles {
  font-weight: 700;
  text-decoration: none;
}

@mixin primary-button {
  @include base-button-styles;
  @include button-style(
    $background: get-color(primary),
    $background-hover: $dark-gray,
    $color: $button-color
  );

  &.alt-1 {
    background: $light-gray;
    color: $black;

    &:hover {
      background: $medium-gray;
    }
  }
}

@mixin secondary-button {
  @include base-button-styles;
  @include button-style(
    $background: transparent,
    $background-hover: transparent,
    $color: get-color(primary)
  );
  font-size: rem-calc(22);
  padding: 0;
  text-decoration: underline;

  &:focus,
  &:hover {
    color: get-color(napa-orange);
  }
}

@mixin tertiary-button {
  @include base-button-styles;
  @include button-style(
    $background: transparent,
    $background-hover: transparent,
    $color: get-color(primary)
  );
  border: 1px solid $light-gray;
  font-size: rem-calc(14);

  &:focus,
  &:hover {
    color: get-color(napa-orange);
  }
}


@mixin napapijri-button {
  @include foundation-button;
  // Add button element. F6 doesn't style the
  // <button> element directly, only .button

  button,
  .button {
    @include base-button-styles;

    // TODO: Remove with VFDP-57839
    // Shim for Foundation 4 `expand` class.
    &.expand {
      @include button-expand;
    }

    // Disabled style
    &.disabled,
    &[disabled] {
      @include button-disabled(get-color(primary));
    }

    &.primary {
      @include primary-button;
    }

    &.secondary {
      @include secondary-button;
    }

    &.tertiary {
      @include tertiary-button;
    }

    // More/Less buttons have up and down arrows from the icon font
    &.more,
    &.less {
      @include button-style(
        $background: transparent,
        $background-hover: transparent,
        $color: get-color(primary)
      );
      padding: 0;
      text-decoration: none;

      &:hover {
        color: get-color(napa-orange);
      }

      &::after {
        display: block;
        transition: transform 200ms;
      }
    }

    &.more {
      &::after {
        @include icomoonFont($icon-arrow-down-indicator);
      }

      &:hover {
        &::after {
          transform: translateY(10%);
        }
      }
    }

    &.less {
      &::after {
        @include icomoonFont($icon-arrow-up-indicator);
      }

      &:hover {
        &::after {
          transform: translateY(-10%);
        }
      }
    }

    // Remove default button styles from close button. Used in modals, tooltips, etc
    &.close-button {
      background: transparent;
      margin: 0;
      padding: 0;

      &:focus,
      &:hover {
        background: transparent;
      }
    }
  }

  button {
    @include button;
  }
}
