// ============================================================================================================
// Footer
// -----------------------------------------------------------------------------------------------------------

$include-html-footer-classes: false;
@import 'global/footer';

footer {

  @include breakpoint(large) {
    margin-top: em-calc(60);
  }

  .anchor-top {
    border-bottom: $footerBorderTop;
  }

  .collapsed .heading {
    color: $black;
  }

  .footer-content {
    .heading {
      &::before {
        display: none; // remove accordion arrow
      }
    }

    a {
      text-decoration: none;
    }
  }

  .footer-locator,
  .footer-social,
  .footer-signup {

    // Border top must have padding on both sides
    &::before {
      content: '';
      width: 100%;
      border-top: 1px solid $light-gray;
      margin-bottom: em-calc(40);
      display: block;

      @include breakpoint(medium) {
        display: none;
      }
    }
  }

  .signup {
    .input-group-button {
      align-items: center;
    }
  }

  .footer-locator {
    p {
      font-size: em-calc(12);

      @include breakpoint(medium) {
        font-size: em-calc(14);
      }
    }

    a.button {
      @include secondary-button();
      font-size: em-calc(16);

      @include breakpoint(medium) {
        text-align: left;
      }
    }
  }
}
