// ============================================================================================================
// Footer
// -----------------------------------------------------------------------------------------------------------
$include-html-footer-classes: $include-html-classes !default;

// Footer Layout
$footerBackground: null !default;
$footerBorderTop: null !default;
$footerColor: null !default;
$footerFont: null !default;
$footerMargin: null !default;
$footerMarginMedium: null !default;
$footerPadding: null !default;
$footerPaddingMedium: null !default;
$footerPaddingLarge: null !default;
$footerSectionPadding: 0 0 em-calc(30) !default;
$footerSectionPaddingMedium: null !default;
$footerBrandingSectionPadding: null !default;
$footerBrandingSectionPaddingMedium: $footerSectionPaddingMedium !default;
$footerContentSectionPadding: $footerSectionPadding !default;
$footerContentSectionPaddingMedium: $footerSectionPaddingMedium !default;
$footerExtraSectionPadding: $footerSectionPadding !default;
$footerExtraSectionPaddingMedium: $footerSectionPaddingMedium !default;
$footerUtilSectionPadding: $footerSectionPadding !default;
$footerUtilSectionPaddingMedium: $footerSectionPaddingMedium !default;

// Footer Accordion Menu (Small Screens)
$footerAccordionItemBorder: 1px solid $black !default;
$footerAccordionItemPadding: column-gutter()/2 !default;
$footerAccordionItemPaddingMedium: 0 0 column-gutter()/2 !default;
$footerAccordionIcon: $icon-arrow-up-indicator-open !default;
$footerAccordionIconClosed: $icon-arrow-down-indicator-open !default;
$footerAccordionIconOffset: column-gutter()/2 !default;
$footerAccordionIconSize: rem-calc(14px) !default;
$footerAccordionIconWeight: null !default;
$footerAccordionContentBackground: null !default;

// Footer Headings
$footerHeadingColor: null !default;
$footerHeadingFont: null !default;
$footerHeadingMargin: null !default;
$footerHeadingMarginMedium: null !default;
$footerHeadingPadding: 0 0 column-gutter()/2 !default;
$footerHeadingPaddingMedium: null !default;
$footerHeadingSize: rem-calc(18) !default;
$footerHeadingSizeMedium: null !default;
$footerHeadingWeight: 700 !default;
$footerHeadingTransform: uppercase !default;
$footerFontFamily: null !default;

// Footer Subheadings
$footerSubheadingBorder: $footerAccordionItemBorder !default;
$footerSubheadingColor: null !default;
$footerSubheadingFont: null !default;
$footerSubheadingSize: rem-calc(14) !default;
$footerSubheadingWeight: null !default;
$footerSubheadingPadding: $footerHeadingPadding !default;
$footerSubheadingPaddingMedium: null !default;

// Footer Links
$footerNavItemBorder: null !default;
$footerNavItemColor: null !default;
$footerNavItemColorHover: null !default;
$footerNavItemFont: null !default;
$footerNavItemPadding: $footerHeadingPadding !default;
$footerNavItemPaddingMedium: null !default;
$footerNavItemSize: rem-calc(14) !default;
$footerNavItemWeight: null !default;
$footerNavListMargin: 0 !default;
$footerNavListMarginMedium: null !default;

// Email Signup
$footerSignupMarginBottom: null !default;
$footerSignupLegalTextColor: null !default;
$footerSignupLegalTextSize: rem-calc(12) !default;
$footerSignupLegalErrorColor: null !default;
$footerSignupLegalTermsLinkFontWeight: null !default;
$footerSignupLegalTermsLinkTextDecoration: null !default;

// Social Links (Will default from global social icon styles unless overwritten here)
$footerSocialMarginBottom: null !default;
$footerSocialIconBackground: null !default;
$footerSocialIconBorder: null !default;
$footerSocialIconColor: null !default;
$footerSocialIconSize: null !default;
$footerSocialIconHeight: null !default;
$footerSocialIconMargin: 0 0 8px !default;
$footerSocialIconMarginMedium: null !default;
$footerSocialIconWidth: null !default;
$footerSocialIconHoverBackground: null !default;
$footerSocialIconHoverBorder: null !default;
$footerSocialIconHoverColor: null !default;

// Store Locator
$footerLocatorMarginBottom: null !default;

// Region Selector
$footerRegionBackground: null !default;
$footerRegionBorder: null !default;
$footerRegionFontSize: null !default;
$footerRegionMargin: 0 auto !default;
$footerRegionMarginMedium: 0 !default;
$footerRegionWidth: 60% !default;
$footerRegionWidthMedium: auto !default;
$footerRegionWidthLarge: null !default;
$footerRegionFlagMargin: null !default;
$footerRegionSelectedBackground: null !default;
$footerRegionSelectedBorder: null !default;
$footerRegionSelectedColor: null !default;
$footerRegionSelectedFlagMargin: null !default;
$footerRegionSelectedIconMargin: null !default;
$footerRegionSelectedHeight: null !default;
$footerRegionSelectedMargin: 0 !default;
$footerRegionSelectedNamePadding: null !default;
$footerRegionSelectedPadding: null !default;
$footerRegionSelectedWeight: null !default;
$footerRegionTextAlign: center !default;
$footerRegionDropdownBackground: null !default;
$footerRegionDropdownBorder: null !default;
$footerRegionDropdownMaxHeight: 250px !default;
$footerRegionDropdownMaxWidthMedium: 200px !default;
$footerRegionItemColor: null !default;
$footerRegionItemPadding: null !default;
$footerRegionItemFontSize: null !default;
$footerRegionItemHoverBg: null !default;
$footerRegionItemHoverColor: null !default;
$footerCountryLabelFont: null !default;
$footerCountryLabelFontSize: null !default;
$footerNavAnchorOpacity: null !default;

// Logo
$footerLogoBgPosition: null !default;
$footerLogoBgSize: 100% !default;
$footerLogoMargin: null !default;
$footerLogoPath: $store-logo !default;
$footerLogoHeight: 75px !default;
$footerLogoWidth: $footerLogoHeight !default;
$footerBrandingBorder: null !default;

// Back To Top Link
$footerBackToTopLinkHide: false !default;
$footerBackToTopLinkColor: null !default;
$footerBackToTopLinkDecoration: null !default;
$footerBackToTopLinkFont: null !default;
$footerBackToTopLinkPadding: null !default;
$footerBackToTopLinkSize: null !default;
$footerBackToTopLinkTransform: null !default;

// Survey button
$surveyButtonHide: true !default;

// Footer
@mixin footer-main {
	background-color: $footerBackground;
	border-top: $footerBorderTop;
	color: $footerColor;
	font-family: $footerFont;
	margin: $footerMargin;
	overflow: hidden;
	padding: $footerPadding;

	.heading {
		color: $footerHeadingColor;
		font-family: $footerHeadingFont;
		font-size: $footerHeadingSize;
		font-weight: $footerHeadingWeight;
		padding: $footerHeadingPadding;
		text-transform: $footerHeadingTransform;
		a {
			color: $footerNavItemColor;
		}
	}

	.subheading {
		color: $footerSubheadingColor;
		font-family: $footerSubheadingFont;
		font-size: $footerSubheadingSize;
		font-weight: $footerSubheadingWeight;
	}

	@include breakpoint(medium) {
		margin: $footerMarginMedium;
		padding: $footerPaddingMedium;

		.heading {
			font-size: $footerHeadingSizeMedium;
			padding: $footerHeadingPaddingMedium;
		}
	}

	@include breakpoint(large) {
		padding: $footerPaddingLarge;
	}
}

@mixin footer-logo {
	background: $footerLogoPath no-repeat;
	background-position: $footerLogoBgPosition;
	background-size: $footerLogoBgSize;
	display: block;
	height: $footerLogoHeight;
	width: $footerLogoWidth;

	&::before {
		content: ' '; // Don't let icon font logo display
	}
}

// Footer branding section (displayed when logo is configured on top of footer)
@mixin footer-branding {
	border-bottom: $footerBrandingBorder;
	padding: $footerBrandingSectionPadding;

	.icon-store-logo {
		@include footer-logo;
		margin: 0 auto;
	}

	@include breakpoint(medium) {
		@if $footerBrandingBorder {
			border: 0;
		}

		padding: $footerBrandingSectionPaddingMedium;
	}
}

// Footer-Content Section
@mixin footer-content {
	padding: $footerContentSectionPadding;

	.columns {
		border-bottom: $footerAccordionItemBorder;
		padding: 0; // remove padding on column allowing accordion to go full-width

		&.collapsed {
			.heading {
				// style the icons for the accordion view when collapsed
				&::before {
					@include icomoonFont($footerAccordionIconClosed, false);
				}
			}
		}
	}

	.column-content {
		background: $footerAccordionContentBackground;
	}

	.heading {
		font-family: $footerFontFamily;
		margin-bottom: $footerHeadingMargin;
		padding: $footerAccordionItemPadding;
		position: relative;

		// style the icons for the accordion view when expanded
		&::before {
			@include icomoonFont($footerAccordionIcon);
			@include vertical-align($position: absolute);
			font-size: $footerAccordionIconSize;
			font-weight: $footerAccordionIconWeight;
			right: $footerAccordionIconOffset;
		}

		@include breakpoint(medium) {
			margin-bottom: $footerHeadingMarginMedium;
		}
	}

	.subheading {
		border-top: $footerSubheadingBorder;
		padding: $footerSubheadingPadding;
	}

	ul {
		list-style-type: none;
		margin: $footerNavListMargin;
	}

	li {
		border-top: $footerNavItemBorder;
		font-size: $footerNavItemSize;
		padding: $footerNavItemPadding;
	}

	a {
		color: $footerNavItemColor;
		display: block;
		font-family: $footerNavItemFont;
		font-weight: $footerNavItemWeight;
		opacity: $footerNavAnchorOpacity;

		&:focus,
		&:hover {
			color: $footerNavItemColorHover;
		}
	}

	@include breakpoint(medium) {
		padding: $footerContentSectionPaddingMedium;

		.columns {
			border: 0;
			padding: 0 column-gutter()/2;
		}

		.column-content {
			background: none;
		}

		.heading {
			padding: $footerAccordionItemPaddingMedium;

			// hide icon from accordion on medium/large screens
			&::before {
				display: none;
			}
		}

		.subheading {
			border: 0;
			padding: $footerSubheadingPaddingMedium;
		}

		ul {
			margin: $footerNavListMarginMedium;
		}

		li {
			border: 0;
			padding: $footerNavItemPaddingMedium;
		}

		a {
			display: inline-block;
			padding: 0;
		}
	}
}

// Footer-Extra Section
@mixin footer-extra {
	padding: $footerExtraSectionPadding;

	@include breakpoint(medium) {
		padding: $footerExtraSectionPaddingMedium;
	}
}

// Store Locator Column
@mixin footer-locator {
	margin-bottom: $footerLocatorMarginBottom;

	p {
		margin-bottom: 1.25em;
	}
}

// Email Signup Column
@mixin footer-signup {
	margin-bottom: $footerSignupMarginBottom;

	.legal-terms-container {
		font-size: 1em;
		text-align: left;

		.columns {
			@include grid-column-collapse;
		}

		label {
			color: $footerSignupLegalTextColor;
			font-size: $footerSignupLegalTextSize;
			font-weight: normal;
			position: relative;
			a {
				color: $footerNavItemColor;
			}
			&.error {
				color: $footerSignupLegalErrorColor;

				small.error {
					font-size: 1em;
				}
			}

			.legal-terms-link {
				font-weight: $footerSignupLegalTermsLinkFontWeight;
				text-decoration: $footerSignupLegalTermsLinkTextDecoration;
			}
		}
	}
}

// Social Column
@mixin footer-social {
	margin-bottom: $footerSocialMarginBottom;

	.social-icon {
		background-color: $footerSocialIconBackground;
		border: $footerSocialIconBorder;
		color: $footerSocialIconColor;
		font-family: icomoon;
		font-size: $footerSocialIconSize;
		height: $footerSocialIconHeight;
		line-height: $footerSocialIconHeight;
		margin: $footerSocialIconMargin;
		width: $footerSocialIconWidth;

		&:hover {
			background: $footerSocialIconHoverBackground;
			border: $footerSocialIconHoverBorder;
			color: $footerSocialIconHoverColor;
		}
	}

	@include breakpoint(medium) {
		.social-icon {
			margin: $footerSocialIconMarginMedium;
		}
	}

	ul {
		margin-left: 0;

		li {
			display: inline;
			list-style: none;
		}
	}
}

// Footer-Util Section
@mixin footer-util {
	padding: $footerUtilSectionPadding;

	.brand-logo {
		display: inline-block;
		margin-right: $footerLogoMargin;
		vertical-align: middle;
	}

	.icon-store-logo {
		@include footer-logo;
	}

	.util-center {
		.brand-logo {
			display: block;
			margin: 0 auto;
		}
	}

	.util-left {
		.brand-logo {
			float: left;
		}

		.customHtmlBlock2 {
			display: flex;
		}
	}

	.country-label-text {
	    @include vertical-align($position: absolute);
	    font-family: $footerCountryLabelFont;
	    font-size: $footerCountryLabelFontSize;
	    left: 10px;
	}

	@include breakpoint(medium) {
		padding: $footerUtilSectionPaddingMedium;
	}
}

// Back To Top Link
@mixin footer-back-to-top-link {
	@if $footerBackToTopLinkHide {
		// !important needed to override visibility classes
		display: none !important; // sass-lint:disable-line no-important
	} @else {
		color: $footerBackToTopLinkColor;
		display: inline-block;
		font-family: $footerBackToTopLinkFont;
		font-size: $footerBackToTopLinkSize;
		padding: $footerBackToTopLinkPadding;
		text-align: center;
		text-decoration: $footerBackToTopLinkDecoration;
		text-transform: $footerBackToTopLinkTransform;
		width: 100%;
	}
}

// Survey button container 
@mixin footer-survey-button-container {
	@if $surveyButtonHide {
		display: none !important; // sass-lint:disable-line no-important
	} @else {
		display: flex;
		justify-content: center;
	}
}

// Survey button
@mixin footer-survey-button {
	@if $surveyButtonHide {
		display: none !important; // sass-lint:disable-line no-important
	} @else {
		border: none;
		background-color: transparent;
		color: get-color(success);
		font-family: get-font('Poppins') !important; // sass-lint:disable-line no-important
		font-size: rem-calc(14);
		font-weight: 600;
		height: rem-calc(18);
		letter-spacing: 0;
		line-height: rem-calc(18);
		margin: rem-calc(8) 0 rem-calc(24) 0 !important; // sass-lint:disable-line no-important
		padding: 0;
		text-align: center;
		text-decoration: underline;
		text-transform: uppercase;
	}
}

// Region Selector
@mixin footer-region-dropdown {
	@include clearfix();
	background: $footerRegionBackground;
	border: $footerRegionBorder;
	font-size: $footerRegionFontSize;
	position: relative;
	margin: $footerRegionMargin;
	width: $footerRegionWidth;

	a {
		display: block;
		text-align: $footerRegionTextAlign;
		text-decoration: none;
	}

	img {
		margin: $footerRegionFlagMargin;
	}

	.selected-region {
		background-color: $footerRegionSelectedBackground;
		border: $footerRegionSelectedBorder;
		color: $footerRegionSelectedColor;
		font-size: $footerRegionFontSize;
		font-weight: $footerRegionSelectedWeight;
		height: $footerRegionSelectedHeight;
		margin: $footerRegionSelectedMargin;
		padding: $footerRegionSelectedPadding;

		img {
			margin: $footerRegionSelectedFlagMargin;
		}

		.icon-arrow-down-indicator-open {
			margin: $footerRegionSelectedIconMargin;
		}
	}

	.country-title {
		display: inline-block;
		padding: $footerRegionSelectedNamePadding;
	}

	// dropdown list
	.dropdown-pane {
		background: $footerRegionDropdownBackground;
		border: $footerRegionDropdownBorder;
		max-height: $footerRegionDropdownMaxHeight;
		overflow: auto;
		padding: 0;
		right: 0;
		width: 100%;

		ul {
			list-style: none;
			margin-bottom: 0;
			padding: 0;
		}

		a {
			box-sizing: content-box;
			color: $footerRegionItemColor;
			font-size: $footerRegionItemFontSize;
			padding: $footerRegionItemPadding;
			text-align: right;

			&:hover {
				background: $footerRegionItemHoverBg;
				color: $footerRegionItemHoverColor;
			}
		}
	}

	@include breakpoint(medium) {
		float: right;
		margin: $footerRegionMarginMedium;
		width: $footerRegionWidthMedium;

		a {
			text-align: right;
		}

		.selected-region {
			float: right;
		}

		.dropdown-pane {
			max-width: $footerRegionDropdownMaxWidthMedium;
		}
	}

	@include breakpoint(large) {
		width: $footerRegionWidthLarge;
	}
}


// Legal Reveal Overlay
@mixin footer-legal-modal {
	max-height: 550px;
	overflow-y: auto;
}

@mixin footer {
	footer {
		@include footer-main;

		.footer {
			&-branding {
				@include footer-branding;
			}

			&-content {
				@include footer-content;
			}

			&-extra {
				@include footer-extra;
			}

			&-localize {
				@include footer-region-dropdown;
			}

			&-locator {
				@include footer-locator;
			}

			&-signup {
				@include footer-signup;
			}

			&-social {
				@include footer-social;
			}

			&-util {
				@include footer-util;
			}

			.anchor-top {
				@include footer-back-to-top-link;
			}

			div.survey-button-container {
				@include footer-survey-button-container;
			}

			button.survey-button.survey-button-js {
				@include footer-survey-button;
			}
		}

		// Footer Legal Reveal Overlay
		#footerLegal {
			@include footer-legal-modal;
		}
	}
}
